import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import { Navigate } from 'react-router-dom';

import { SAMPLE_VERSION } from '../version';
import addScriptTag from '../documentHelper';
import getSDKScript from '../scriptHelper';

const PrivateRoute = ({ children }) => {
  const pageName = children && children.type ? children.type.name : "";
  const navigate = useNavigate();

  const [interfaceReady, setInterfaceReady] = useState(false)
  const [configurationKey, setConfigurationKey] = useState(null)
  const [configurationLocation, setConfigurationLocation] = useState(null)
  const [environment, setEnvironment] = useState(null)
  const [language, setLanguage] = useState(null)
  const [useBaseSdkLocation, setUseBaseSdkLocation] = useState(true)
  const [version, setVersion] = useState(null)
  const [defaultPage, setDefaultPage] = useState(null)
  const [crossDomain, setCrossDomain] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState('')
  const [brandData, setBrandData] = useState('')
  const [referringPage, setReferringPage] = useState(null)
  const [registrationReferrer, setRegistrationReferrer] = useState(null)
  const [authenticatedUser, setAuthenticatedUser] = useState(null)
  const sdkLocationRef = useRef(null)
  const autoLaunchRef = useRef(false)
  const embedContainerRef = useRef(null)

  const useRenderedRef = (value) => {
    const ref = useRef(value)
    const [, forceRender] = useState(value)
  
    function updateState(newState) {
      ref.current = newState
      forceRender(newState)
    }
  
    return [ref, updateState]
  };
  
  const [embedStyleRef, setEmbedStyle] = useRenderedRef(null)
  const [embeddingRef, setEmbedding] = useRenderedRef(false)

  const EmbedStyle = Object.freeze({
    NONE: { key: 'none', name: 'None' },
    PAGE: { key: 'page', name: 'Page' },
    PANEL: { key: 'panel', name: 'Panel' },
  });
  
  const { userId, setUserId, isAuthenticated, setIsAuthenticated } = useAppContext();

  const initialize = () => {
    window.nbc.identity.customEventAttributes = { custom_app_data: 'web-Sample' }
    const urlParams = new URLSearchParams(window.location.search)

    const key = urlParams.get('key') ?? 'nbc'
    setConfigurationKey(key)

    const configurationLocationParam = urlParams.get('configLocation') ?? ''
    setConfigurationLocation(configurationLocationParam)

    const env = urlParams.get('env') ?? 'dev'
    setEnvironment(env)

    const languageParam = urlParams.get('language') ?? 'en'
    setLanguage(languageParam)

    const useBaseSdkLocationParam = urlParams.has('useBaseSdkLocation')
      ? urlParams.get('useBaseSdkLocation') === 'true'
      : process.env.REACT_APP_USE_BASE_SDK_LOCATION === 'true'
    setUseBaseSdkLocation(useBaseSdkLocationParam)

    const versionParam = urlParams.get('version') ?? SAMPLE_VERSION.charAt(0)
    setVersion(versionParam)

    const crossDomainParam = urlParams.get('crossDomain') === 'true'
    setCrossDomain(crossDomainParam)

    const redirectUrlParam = urlParams.get('redirectUrl')
    if (redirectUrlParam) {
      localStorage.setItem('nbcRedirectUrl', redirectUrlParam)
      setRedirectUrl(redirectUrlParam)
    }

    const brandDataParam = urlParams.get('brandData')
    setBrandData(brandDataParam)

    const debug = urlParams.has('debug') ? urlParams.get('debug') === 'true' : true

    setReferringPage(urlParams.get('referringPage'))
    setRegistrationReferrer(urlParams.get('registrationReferrer'))

    window.nbc.identity.initialize('wickedweb', {
      configLocationHost: null,
      debug,
      env: 'stage',
      language: languageParam,
      useBaseSdkLocation: true,
      version: '2',
      crossDomain: true,
    });

    // window.nbc.identity.initialize('wickedweb', {
    //   // configLocationHost: 'https://wicked-gwp.s3.us-east-2.amazonaws.com',
    //   configLocationHost: null,
    //   debug,
    //   env: 'stage',
    //   language: languageParam,
    //   useBaseSdkLocation: true,
    //   version: '2',
    //   crossDomain: true,
    // })

    setDefaultPage(urlParams.get('defaultPage'))
    setEmbedStyle(urlParams.get('embed') ?? EmbedStyle.PANEL.key)

    autoLaunchRef.current = urlParams.get('autoLaunch') === 'true'
    if (!autoLaunchRef.current) setInterfaceReady(true)
  };

  const launchSdk = () => {
    //TODO: Bring me back in
    // window.nbc.identity.launch({
    //   referringPage,
    //   defaultPage,
    //   brandData, //specify Page
    //   // redirectUrl: 'https://local.moviebonus.com:3000/',
    //   // redirectUrl: 'https://localhost:3000/',
    //   redirectUrl: 'https://d2nbg7m24ttiy8.cloudfront.net/'
    //   //   registrationReferrer: registrationReferrer ? `${registrationReferrer}-launch` : undefined,
    // })

    let path = '/';
    switch(pageName) {
      case 'Collection':
        path = '/collection/';
        break;
      case 'ClaimCard':
        path = '/claim-card';
        break;
    }

    window.nbc.identity.launch({
      registrationReferrer: 'None',
      // redirectUrl: 'https://local.moviebonus.com:3000' + path,
      // redirectUrl: 'https://localhost:3000',
      // redirectUrl: 'https://d2nbg7m24ttiy8.cloudfront.net' + path
      redirectUrl: 'https://stage.moviebonus.com' + path
    });
  };

  const prettyNameForStatus = (status) => {
    switch (status) {
      case 'complete':
        return 'Complete'
      case 'incomplete':
        return 'Incomplete'
      case 'notStarted':
        return 'Not Started'
      default:
        return `Unknown status ${status}`
    }
  };

  const pageEmbedActive = () => embedStyleRef.current === EmbedStyle.PAGE.key &&
  embeddingRef.current

  const removeAllEmbedContainerChildren = () => {
    const { current: parent } = embedContainerRef
    if (parent) {
      while (parent.firstChild) {
        parent.removeChild(parent.firstChild)
      }
    }
  };

  useEffect(() => {
    const handleSdkStatusChange = (status) => {
      console.log('sdk status changed: ', status)
      switch (status) {
        case 'initialized': {
          const storedOverridesJsonString = localStorage.getItem('launch_darkly_overrides')
          let overrides = {}
          if (storedOverridesJsonString) {
            overrides = JSON.parse(storedOverridesJsonString)
            window.nbc.identity.overrideLaunchDarklyConfigurations(overrides)
          }
          if (autoLaunchRef.current) launchSdk()
          break
        }
        default:
        // do nothing
      }
    };

    const handleAuthenticationStateChange = (state) => {
      console.log('Authentication state changed', state);
      if (state === 'authenticated') {
        prettyNameForStatus(window.nbc.identity.signUpCompletionStatus());
        prettyNameForStatus(window.nbc.identity.completeProfileCompletionStatus());
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        // navigate('/');
        launchSdk();
      }
    };

    const handleUserChange = (user) => {
      console.log('User changed. Here\'s new user: ', user);
      setAuthenticatedUser(user);
      if (user) {
        setUserId(user._id);
      }
    };

    const handleWindowStateChange = (windowState) => {
      console.log('Window state changed to: ', windowState)
      if (windowState === 'opened' && autoLaunchRef.current) {
        setInterfaceReady(true)
      }
      else if (windowState === 'closed' && pageEmbedActive()) {
        setEmbedding(false)
        removeAllEmbedContainerChildren()
      }
    };

    const onSdkLoaded = () => {
      console.log('LOAD SDK');
      initialize()
      window.nbc.identity.on('status', handleSdkStatusChange)
      window.nbc.identity.on('authenticationState', handleAuthenticationStateChange)
      window.nbc.identity.on('user', handleUserChange)
      window.nbc.identity.on('windowState', handleWindowStateChange)
    }

    const loadIdentitySdk = () => {
      console.log(window.nbc);
      if (!window.nbc?.identity) {
        sdkLocationRef.current = getSDKScript()
        console.log(sdkLocationRef.current);
        addScriptTag(
          'nbc-identity-sdk-js',
          sdkLocationRef.current,
          { async: false, defer: false, onload: onSdkLoaded },
        )
      }
    };
    loadIdentitySdk();
  }, []);

  // Check if user is authenticated
  if (!isAuthenticated) {
    // Redirect to the sign-in page if not authenticated
    // window.nbc.identity.initialize('config', {
    //   configLocationHost: 'https://wicked-gwp.s3.us-east-2.amazonaws.com',
    //   debug: false,
    //   env: 'dev',
    //   language: 'en',
    //   useBaseSdkLocation: true,
    //   version: '2',
    //   crossDomain: true,
    // });

    // return <Navigate to="/" replace />;
  }
  return children;
};

export default PrivateRoute;
