import React, { useState } from 'react';
import { useAppContext } from '../AppContext';
import { useNavigate } from 'react-router-dom';
import { Box, Grid2, CircularProgress, Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './CardRedeemed.css';
import { API_BASE_URL } from '../constants';

const CardRedeemed = ({ allCollections, redeemedCards }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLoading, setIsLoading] = useState(false);

  const universal_logo = require('../assets/universal.png');
  const elphaba = require('../assets/elphaba_small.png');
  const glinda = require('../assets/glinda_small.png');
  const fiyero = require('../assets/fiyero_small.png');
  const madame = require('../assets/madame_small.png');
  const nessarose = require('../assets/nessarose_small.png');
  const wizard = require('../assets/wizard_small.png');
  const wicked_logo = require('../assets/wicked_logo_small.png');

  const { reward, collectionIds } = useAppContext();

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const numRedeemedCards = Object.keys(redeemedCards).length;

  const getOptimizedCard = (card) => {
    switch (card.name.toLowerCase()) {
      case 'elphaba':
        return elphaba;
      case 'glinda':
        return glinda;
      case 'fiyero':
        return fiyero;
      case 'madame':
      case 'madame morrible':
        return madame;
      case 'nessarose':
        return nessarose;
      case 'wizard':
      case 'the wizard':
        return wizard;
    }
  };
  
  return (
    <div>
      {/* <img src={wicked_logo} alt="Wicked logo" className="redeemed-header-image" onClick={() => navigate('/')} />

      <button className='redeemed-logout-btn'>Log out</button> */}

      <h1
        style={{
          color: '#fff',
          textAlign: 'center',
          fontWeight: 600,
          fontSize: isSmallScreen ? '44px' : '56px',
          marginBottom: '12px',
          padding: isSmallScreen ? '0 12px' : 0,
        }}
      >
        Thanks for watching <i>Wicked</i> in Theaters!
      </h1>

      {(!isLoading && numRedeemedCards > 0) && <h2
        style={{
          color: '#b5b5b5',
          fontSize: isSmallScreen ? '24px' : '34px',
          textAlign: 'center',
          fontWeight: 300,
          marginTop: 0,
          padding: isSmallScreen ? '0 12px' : 0,
        }}
      >
        You've redeemed ({numRedeemedCards}) digital card{numRedeemedCards > 1 ? 's' : ''}.
      </h2>
      }
      <button
        style={{
          // alignItems: 'center',
          // justifyContent: 'center',
          paddingTop: '15px',
          paddingBottom: '38px',
          fontSize: 20,
          marginBottom: '20px',
          marginTop: '0px'
        }}
        className="button-glow"
        onClick={() => {
          navigate('/collection')
        }}
      >
        View Collection
      </button>
      <Grid2 container spacing={2}
        sx={{ padding: 0, justifyContent: 'center', display: 'flex', flexWrap: 'wrap', gap: '20px', padding: '20px', maxWidth: '1200px', margin: '0 auto', marginBottom: 10 }}
      // xs={12} sm={12} md={12} lg={12} xl={12}
      >
        {numRedeemedCards == 0 ? (
          <CircularProgress color="success" size={50} style={{ marginTop: 40, marginBottom: 30 }} />
        ) : (allCollections
          .filter((collection) => {
            return redeemedCards[collection.collection_id] == 1;
          })
          .map((collection, i) => {
            return (
              <Grid2 key={i}>
                <img src={getOptimizedCard(collection)}
                  style={{ width: '20rem', height: 'auto' }} />
              </Grid2>
            );
          }
          ))}
      </Grid2>
      {/* <img 
            src={universal_logo}
            className='universal-logo'
          /> */}
    </div>
  );
};

export default CardRedeemed;