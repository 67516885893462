import { SAMPLE_VERSION as fallbackVersion } from './version'

const getSDKScript = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const environment = urlParams.get('env')
  const urlVersion = urlParams.get('version')
  const version = urlVersion ?? fallbackVersion.charAt(0)

  if (environment === 'production') {
    return `${process.env.REACT_APP_PROD_IDENTITY_ORIGIN}/websdk/v${version}/identity-sdk.js`
  } if (environment === 'stage') {
    return `${process.env.REACT_APP_STAGE_IDENTITY_ORIGIN}/websdk/v${version}/identity-sdk.js`
  }
  return urlVersion || !process.env.REACT_APP_DEFAULT_IDENTITY_SDK
    ? `${process.env.REACT_APP_DEV_IDENTITY_ORIGIN}/websdk/v${version}/identity-sdk.js`
    : process.env.REACT_APP_DEFAULT_IDENTITY_SDK
}

export default getSDKScript
