import '../pages/Home.css';
import { Box } from '@mui/material';

const universal_logo = require('../assets/logos.png');
const aptos_logo = require('../assets/aptos_logo.png');

const Footer = () => {

  return (
    <Box>
      <img src={universal_logo} className='universal-logo2' />
    </Box>
  );
};

export default Footer;